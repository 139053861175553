import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid =()=> {



    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">

            <h2 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App </span></span>
              For Rarible Clone App Development</h2>
            <p className="pharagraph head">Launch a user-friendly and eye-catching NFT Marketplace mobile app like Rarible
            </p>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph" >
                By choosing our first-class Rarible clone app development service, you can develop an outstanding NFT Marketplace mobile application like Rarible instantly with all cutting-edge features and security protocols. Our Rarible clone app allows buyers and sellers to deal with non-fungible tokens as well as digital pieces of art. Besides, it is compatible with all the major platforms such as Android and IOS.
              </p>
              <p className="pharagraph" >The rarible clone app is a white-label NFT Marketplace application. So you can alter the core functionalities, personalize the design of the app, and incorporate changes that are within the realm of the clone app. Additionally, you can also enable additional add-ons on your Rarible clone app as per your necessities. Our premium Rarible clone app works efficiently without any glitches. Also, it is multi-tested by a team of skilled quality analysts that ensures the app is error-free and free from all technical issues.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/rarible/rarible-clone-app-development.png" alt="rarible clone app development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>

          <div className='mobile-on'>
            <ButtonComponent />
          </div>
        </div>
      </section>
    )
}

export default OurAndroid