import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h2 className="heading-h2">Our <span className="heading-h3 bluecolor">Step-by-Step Process</span> of Rarible Clone Development </h2>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for Rarible clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/rarible/development-approach.svg" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3 >Requirement Gathering</h3>
            <p className="pharagraph" >Being an experienced team of blockchain developers, we are in a position to analyze the feasibility of your project. We will analyze it and make the plan that will not fail.
            </p>
            <br />
            <h3 >Planning </h3>
            <p className="pharagraph" >Taking the Rarible clone as the background, we will create a wireframe as well as a prototype to give you a real world feel of how your NFT Marketplace powered by our Rarible clone script will look and feel. It will be as if you’re using a real product.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h3 >Designing</h3>
            <p className="pharagraph" >Based on the analysis and the approval of the prototype, we will design and develop your NFT platform using our Rarible clone script. This includes coding and UI/UX design as per your preferences and branding needs.</p>
            <br />
            <h3 >Development</h3>
            <p  className="pharagraph">After developing, designing and testing, we will deploy the platform on the web. In case you have an app, we will also deploy the rarible clone script powered mobile app on Apple and Google Play Stores.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/rarible/designing.svg" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img className='lazyload' width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/rarible/testing-and-deployment.svg" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3 >Testing</h3>
            <p  className="pharagraph">This is just to make sure that everything works the way it should and there are no kinks.
            </p>
            <br />
            <h3 >Deployment</h3>
            <p  className="pharagraph mb-0">The application will be deployed on its appropriate platform along with the Rarible clone script.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach