import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Rarible</span> Clone Script</h1>
                <p className="sub-heading">Create your fully branded NFT Marketplace like Rarible with elite features by using our custom-made Rarible clone.</p>
                <ul>
                  <li className="pharagraph">An innovative solution to build an NFT Marketplace Platform.
                  </li>
                  <li className="pharagraph">This NFT Marketplace is built on the trusted blockchain network.
                  </li>
                  <li className="pharagraph">Customizable & Cost Effective solution.
                  </li>
                </ul>
                <div className="banner-btn">
                  <ButtonStartfree />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img className='lazyload' width="876px" height="501px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/rarible-laptop-frame.png" alt="rarible clone laptop front view" />
                <div className="outder">
                  <Slider {...settings}>
                    <img className='lazyload' width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/rarible-clone-script.png" alt="white label rarible clone script software" />
                    <img className='lazyload' width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/rarible-clone.png" alt="rarible clone script" />
                    <img className='lazyload' width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/rarible.png" alt="rarible-clone" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner