import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor">Add on Modules</span> of our </span>
              Rarible Clone Software</h2>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  NFT Virtual Land
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Airdrop and Mystery boxes
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Referral and Affiliate System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Customer Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Information center
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">NFT Virtual Land
                      </h3>
                      <p className="pharagraph">NFT Virtual Land is a digital ownable land in the Metaverse platform where it can be used for Advertising, Socializing, Gaming and workspace. Get started with buying NFT Lands like Sandbox, Axie Infinity etc..
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img className='lazyload' width="399px" height="316px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/nft-virtual-land.png" alt="NFT Virtual Land" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Airdrop and Mystery boxes
                      </h3>
                      <p className="pharagraph">Rarible Clone script allows to provide airdrop and mystery boxes with different collectibles nature of NFTs such as Rare, Super rare to keep the users engaged and enthusiastic with the platform.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img className='lazyload' width="386px" height="324px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/airdrop-and-mystery-boxes.png" alt="Airdrop and Mystery boxes" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Referral and Affiliate System
                      </h3>
                      <p className="pharagraph">The Referral system acts as an inbuilt marketing and promotion tool for the product to grab more user base by rewarding the patrons with NFTs and cryptocurrencies. Our Clone script supports the Multi tier Affiliate system to incentivize the affiliates for recruiting new affiliates.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img className='lazyload' width="466px" height="311px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/referral-and-affiliate-system.png" alt="Referral and Affiliate System " />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Customer Support</h3>
                      <p className="pharagraph">Our Rarible clone script provides live chat support, where your users can initiate a chat with admin. Even when admin is  not available our chatbots can provide  automated responses.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img className='lazyload' width="462px" height="281px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/customer-support.png" alt="Customer Support" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Information center
                      </h3>
                      <p className="pharagraph">The blog acts as an information center for the users. Typically, a business will use a blog to help the business's website rank on search engines. Admin can update the blog section by using the CMS system.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img className='lazyload' width="374px" height="269px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/information-center.png" alt="Information center" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon