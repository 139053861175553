import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const WhyShould =()=> {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you Build an </span>
              NFT Marketplace like Rarible using  Rarible Clone script?
              </h2> 
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img className='lazyload' width="577px" height="684px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/why-should-nft-using-rarible.png" alt="rarible clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >It lets your users trade and bid on different digital collectibles & assets in the name of non-fungible tokens. Such as Artwork, in-game components, video, audio, GIFs, and more.
                </li>
                <li className="pharagraph" >You can develop and deploy a fully functional NFT Marketplace like Rarible within a week. 
                </li>
                <li className="pharagraph" >The architecture and other functions are completely based on the Ethereum network
                </li>
                <li className="pharagraph" >As a proprietor of the platform, you can modify the theme, color, components functionalities, and other add-ons according to your wish and business concepts.
                </li>
                <li className="pharagraph" >By connecting the wallet on the platform, users can showcase trending digital collectible assets and list them for trading.
                </li>
                <li className="pharagraph" >Comes with astounding admin and user dashboard</li> 
                <li className="pharagraph" >You can generate high ROI by making commissions on direct selling, bidding, and buying of NFTs.
                </li>
                <li className="pharagraph" >Completely decentralized and 1005 error-free NFT Marketplace script.
                </li>
                <li className="pharagraph" >No need for any technical expertise
                </li>
                <li className="pharagraph" >Highly secure transactions based on smart contracts.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default WhyShould