import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className='bluecolor'>What is</span> Rarible Clone Script?</h2>
              </div>
            </div>

            <div className="text-center">
              <p className="pharagraph" >Rarible clone script is the ready-to-deploy NFT Marketplace software that holds all the vital features to run an NFT Marketplace. In other words, we can say it is an instant solution to kickstart a flourishing NFT Marketplace business in the NFT sphere. This script is crafted over blockchain technology that allows your users to buy and sell digital collectibles in the form of NFTs. This Rarible clone helps you to launch a profit-making NFT Marketplace where digital artists can showcase their creations, hold exhibitions, and sell them.
              </p>
              <p className="pharagraph mb-0">The white label Rarible clone allows your users to manage their digital assets as well as non-fungible tokens securely. Digital art or virtual art can be sold, bought, and preserved using your NFT Marketplace that is built by our Rarible clone software. This clone script can be optimized as per your desire and business concepts. Therefore, our premium Rarible clone is the one-stop solution for people who are planning to set their foot in the NFT world by launching an NFT Marketplace similar to Rarible.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis