import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 pb-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose </span>
                Coinsclone For Rarible Clone Script?</h2>
                <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="476px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/rarible-new/why-choose-coinsclone-for-rarible-clone-script.png" alt="Why Choose our Rarible Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is the reputed NFT Marketplace clone script provider in the blockchain industry. Do you want to deploy an NFT Marketplace like Rarible as early as possible? Then partner with us by acquiring our customizable Rarible clone script. We have a team of highly experienced blockchain developers who have rock-solid experience in crafting a superfine Rarible clone software with the latest frameworks.
              </p>
              <p className="pharagraph">Our robust Rarible clone will help you to build an NFT Marketplace on top of the Ethereum blockchain network. Our script holds all the important features that you need to operate an NFT Marketplace in the long run. It allows your users to sell and buy digital artworks directly. Our Rarible clone software is specially designed to encourage all the NFT enthusiasts out there. Artists, videographers, animators, musicians, and all those people can create/bid on a variety of digital collectibles in the name of NFTs and can promote themselves using your NFT Marketplace.
              </p>
              <p className="pharagraph mb-0">Since we have developed and designed the clone script, we know how to make the Rarible clone script work just as you want. We can completely customize it from top to bottom as per your preference. If you wish, we can also integrate add-on modules and trending plug-ins on the platform. If you want to ensure success for your NFT Marketplace business, then purchasing a premium Rarible clone script from Coinsclone will be the optimal solution.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose