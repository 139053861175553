import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h2 className="heading-h2"><span className="bluecolor">Frequently </span>Asked Questions</h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Is it possible to Create an NFT Marketplace like Rarible?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Yes, it’s absolutely possible, when you purchase a ready-to-launch Rarible clone script from the Coinsclone. By utilizing our script, you create and deploy a stunning NFT Marketplace like Rarible within a week at an affordable cost.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Can I customize the Rarible Clone Software you offer?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes, definitely you can modify our premium Rarible clone script as per your preference without any hassle. Our script comes with plenty of customizable options which you have never imagined.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Will my NFT Marketplace be secure if I use the Rarible clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our error-free Rarible clone script comes with inbuilt high-end security features such as end-to-end encryption, SSL implementation, multi-layer authentication, and more. These features safeguard the platform from cyber-attacks and other fraudulent activities.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Can an NFT Marketplace mobile app be built using the Rarible clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">You can create a feature-rich NFT Marketplace with your unique business concepts using our Rarible clone script. You can customize them and launch an NFT trading mobile app 100% similar to Rarible within 7 days. Our rarible clone app has attractive UI/UX and upgraded features.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the features of the Rarible clone software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The Rarible clone script is crafted with extraordinary features such as revenue management, customizable gallery mode, multi-lingual support, bug bounty program, referral program, affiliate system, etc.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection